@tailwind base;
@tailwind components;
@tailwind utilities;



.radial-bars .radial-bar svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  width: 120px;
  height: 160px;
}
.radial-bars .radial-bar .progress-bar{
  stroke-width: 15;
  stroke: #3bace2;
  fill: transparent;
  stroke-dasharray: 502;
  stroke-dashoffset: 502;
  stroke-linecap: round;
  animation: animate-bar 1s linear forwards;
}
@keyframes animate-bar{
  100%{
    stroke-dashoffset: -1;
  }
}

@keyframes showText{
  100%{
    opacity: 1;
  }
}
.path{
  stroke-width: 15;
  stroke: #b809c3;
  fill: transparent;
  stroke-dasharray: 502;
  stroke-dashoffset: 502;
  stroke-linecap: round;
}

.path-1{animation: animate-path1 1s 1s linear forwards;}
.path-2{animation: animate-path2 1s 1s linear forwards;}
.path-3{animation: animate-path3 1s 1s linear forwards;}
.path-4{animation: animate-path4 1s 1s linear forwards;}
.path-5{animation: animate-path5 1s 1s linear forwards;}
.path-6{animation: animate-path6 1s 1s linear forwards;}
.path-7{animation: animate-path7 1s 1s linear forwards;}
.path-8{animation: animate-path8 1s 1s linear forwards;}

@keyframes animate-path1{
  100%{
    stroke-dashoffset: 30;
  }
}
@keyframes animate-path2{
  100%{
    stroke-dashoffset: 50;
  }
}
@keyframes animate-path3{
  100%{
    stroke-dashoffset: 105;
  }
}
@keyframes animate-path4{
  100%{
    stroke-dashoffset: 250;
  }
}
@keyframes animate-path5{
  100%{
    stroke-dashoffset: 90;
  }
}
@keyframes animate-path6{
  100%{
    stroke-dashoffset: 105;
  }
}
@keyframes animate-path7{
  100%{
    stroke-dashoffset: 250;
  }
}
@keyframes animate-path8{
  100%{
    stroke-dashoffset: 450;
  }
}

.radial-bar .percentage{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  font-weight: 500;
  animation: showText 0.5s 1s linear forwards;
  opacity: 0;
}
.radial-bar .text{
  width: 100%;
  position: absolute;
  text-align: center;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  color: #94a3b8;
  font-size: 14px;
  font-weight: 500;
  animation: showText 0.5s 1s linear forwards;
  opacity: 0;
}

@media (max-width: 481px) {
  .radial-bars .radial-bar svg{
    width: 230px;
    height: 250px;
  }
  .radial-bar .percentage{
    font-size: 25px;
  }
  .radial-bar .text{
    bottom: -70px;
    font-size: 20px;
  }
}

.btn {
  background: linear-gradient(
    92.23deg,
    #ff56f6 21.43%,
    #b936ee 50.63%,
    #3bace2 100%,
    #406aff 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.active {
  color: #fff;
  padding: 10px;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[26px] mb-3 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary text-slate-400 text-[14px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply w-[170px] lg:w-[200px] h-[40px] px-10 lg:px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-[600px] flex items-center;
  }
}


